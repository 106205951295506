<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card style="border-radius: 30px !important;" min-height="200">

      <!-- Title -->
      <v-card-title>
        <h3>{{$t('account.profile.unsubscribeDialog.title')}}</h3>
      </v-card-title>

      <v-card-text class="mt-2">
        <p class="font-weight-bold">{{$t('account.profile.unsubscribeDialog.subtitle')}}</p>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="ml-3"
          :loading="isLoadingBtnSave"
          width="150"
          style="border-radius: 10px !important;"
          @click="customSubmit"
          color="error">
          {{$t('account.profile.unsubscribeDialog.buttons.confirm')}}
        </v-btn>
        <v-spacer />
        <v-btn
          class="mr-3 font-weight-bold"
          rounded
          width="150"
          color="primary"
          @click="close(false)"
          style="border-radius: 10px !important;">
          {{$t('common.buttons.cancel')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import dialogMixin from "@/mixins/dialog"

  export default {

    name: "ProfileUnsubscribeDialog",

    mixins: [dialogMixin],

    data() {
      return {
        isLoadingBtnSave: false
      }
    },

    methods: {

      customSubmit() {

        this.isLoadingBtnSave = true;
        this.$http.put(`/map/users/${this.$session.get("id")}/cancel-subscription`, {},
          {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
        .then(res => {
          this.$store.commit("toast/showSuccess", this.$t('account.profile.unsubscribeDialog.success'));

          this.$session.set("jwt", res.data.token);
          this.close(true);
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtnSave = false;
        });
      }
    }
}
</script>

<style scoped>

</style>